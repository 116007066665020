.subbutton {
    position: relative;
    background-color: transparent;
    color: var(--accentColor);
    cursor: pointer;
    border: solid var(--accentColor) 2px;
    border-radius: 10px;
    font-size: 1.5rem;
    text-align: center;
    display: block;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 20px; /* 17px */
    width: 100%;
    text-decoration: none;
    /* transition: all .25s cubic-bezier(.08, .59, .29, .99); */
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box; /* Include padding and border in the button's width */

}

form {
    display: flex; 
    justify-content: center; /* Center the button inside the form */
    width: 100%;
}

.sub-form {
    display: flex; 
    justify-content: center;
}

@media (hover: hover) {
    .subbutton:hover {
        background-color: var(--accentColor);
        color: var(--bgColor);
    }
}

.subbutton:active {
    background-color: var(--accentColor);
    color: var(--bgColor);
}

@media (max-width: 768px) {
    .subbutton {
        padding:40px;
    }
}
/* styles.css */
.success {
    color: green;
  }
  
.error {
    color: red;
}

.successButton{
    
}