@import url('https://fonts.googleapis.com/css?family=Karla:400,700&display=swap');

.logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    padding-top: 30px;
}

.logo-sub {
    font-family: techFizzFont;
    color: whitesmoke;
    text-align: center;
    font-size: 9em;
    padding-top: 3px;
    padding-bottom: 30px;
}

.smaller-logo-sub {
    font-family: techFizzFont;
    color: whitesmoke;
    text-align: center;
    font-size: 2em;
}

:root {
    --bgColor: #223344;
    --bgColor2: #090a0f;
    --accentColor: #FFF;
    --font: 'Karla', sans-serif;
    --delay: .3s;
}

.welcome-area  {
    position: relative;
    height: 100%;
    min-height: 115vh;
    background-color: black;
    background-size: cover;
    z-index: 1;
    margin: 0;
    padding: 0;
    font-family: var(--font);
    animation: 1s ease-out var(--delay) 1 transitionAnimation; /* duration/timing-function/delay/iterations/name */
    animation-fill-mode: forwards;
    background-repeat: no-repeat;
    background-size: cover;
}

#archive {
    position: relative;
    height: 100%;
    min-height: 130vh;
    background-color: black;
    background-size: cover;
    z-index: 1;
    margin: 0;
    padding: 0;
    font-family: var(--font);
    animation: 1s ease-out var(--delay) 1 transitionAnimation; /* duration/timing-function/delay/iterations/name */
    animation-fill-mode: forwards;
    background-repeat: no-repeat;
    background-size: cover;
}

#about {
    position: relative;
    height: 100%;
    min-height: 115vh;
    background-color: black;
    background-size: cover;
    z-index: 1;
    margin: 0;
    padding: 0;
    font-family: var(--font);
    animation: 1s ease-out var(--delay) 1 transitionAnimation; /* duration/timing-function/delay/iterations/name */
    animation-fill-mode: forwards;
    background-repeat: no-repeat;
    background-size: cover;
}

#theteam {
    position: relative;
    height: 100%;
    min-height: 115vh;
    background-color: black;
    background-size: cover;
    z-index: 1;
    margin: 0;
    padding: 0;
    font-family: var(--font);
    animation: 1s ease-out var(--delay) 1 transitionAnimation; /* duration/timing-function/delay/iterations/name */
    animation-fill-mode: forwards;
    background-repeat: no-repeat;
    background-size: cover;
}

#more {
    position: relative;
    min-height: 100vh;
    background-color: black;
    background-size: cover;
    z-index: 1;
    margin: 0;
    padding: 0;
    font-family: var(--font);
    animation: 1s ease-out var(--delay) 1 transitionAnimation;
    animation-fill-mode: forwards;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
}

#userName {
    color: var(--accentColor);
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.25;
    display: block;
    font-family: var(--font);
    width: 100%;
    text-align: center;
    text-decoration: none;
}

#links {
    max-width: 675px;
    width: auto;
    display: block;
    margin-top: 30px;
    margin: 40px auto;
}

.link {
    position: relative;
    background-color: transparent;
    color: var(--accentColor);
    cursor: pointer;
    border: solid var(--accentColor) 2px;
    border-radius: 10px;
    font-size: 1rem;
    text-align: center;
    display: block;
    margin: 10px; /* Uniform margin around each link */
    padding: 10px; /* Adjust padding as needed */
    text-decoration: none;
    box-sizing: border-box; /* Include padding and border in width calculation */
}

#signup {
    max-width: 300px;
    width: auto;
    display: block;
    margin-top: 30px;
    margin: 0px auto;
    color: #FFF;
    text-align: center;
}

#emailInput {
    display: block;
    margin: auto;
}

.subbutton {
    position: relative;
    background-color: transparent;
    color: var(--accentColor);
    cursor: pointer;
    border: solid var(--accentColor) 2px;
    border-radius: 10px;
    font-size: 1.5rem;
    text-align: center;
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 10px; /* 17px */
    text-decoration: none;
    /* transition: all .25s cubic-bezier(.08, .59, .29, .99); */
    -webkit-tap-highlight-color: transparent;
}

.aboutContainer {
    width: 60%;
    margin: 0 auto;
    padding: 40px 40px;  /* Increased padding for more space */
    background-color: #090a0f;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: white; /* Set text color to white */
    box-sizing: border-box; /* Ensure padding is included in the width */
    z-index: 20;
}

.feedback {
    width: 60%;
    margin: 0 auto;
    padding: 40px 40px;  /* Increased padding for more space */
    background-color: #090a0f;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: white; /* Set text color to white */
    box-sizing: border-box; /* Ensure padding is included in the width */
    z-index: 20;
    text-align: center; /* Align text to the left */
    color: white; /* Set text color to white */
}

.aboutText {
    text-align: left; /* Align text to the left */
    color: white; /* Set text color to white */
    font-size: 1.5rem;
}

.video-container {
    left: 0;
    right: 0;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    height: 55%;
    width: 70%;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.spotify-container {
    left: 0;
    right: 0;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    height: 55%;
    width: 70%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.video {
    width:100%;
    height:100%;
    display: flex;
}

@media (hover: hover) {
    .link:hover {
        background-color: var(--accentColor);
        color: var(--bgColor);
    }

    .subbutton:hover {
        background-color: var(--accentColor);
        color: var(--bgColor);
    }


}

.subbutton:active {
    background-color: var(--accentColor);
    color: var(--bgColor);
}

.link:active {
    background-color: var(--accentColor);
    color: var(--bgColor);
}

@media (max-width: 768px) {
    body {
        height:100%;
    }
    .logo {
        width: 700px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        margin-top: 30px;
    }
    .logo-sub {
        font-size: 5em;
        padding-top: 40px;
        padding-bottom: 30px;
    }
    #signup {
        max-width: 300px;
        width: auto;
        display: block;
        margin-top: 30px;
        margin: 40px auto;
    }
    .subbutton {
        padding:40px;
    }
    .aboutContainer {
        width: 90%; /* Make the section wider on mobile for better readability */
    }
    .aboutText {
        text-align: center; /* Center text on smaller screens */
    }
    #links {
        padding-top: 20px;
    }
    .link {
        padding:25px;
    }
    .feedback {
        width: 90%
    }

}
@media (max-width: 1000px) {
    .teamText {
        flex-direction: column;
        gap: 0;
    }

    .founder {
        flex-direction: column;
        text-align: center;
    }

    .founder-img {
        margin-left: 0;
        margin-bottom: 20px;
    }
}

/*-------------------------animations-----------------------*/
@keyframes transitionAnimation {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes animate {
    0% {
      background-position: -500%;
    }
    100% {
      background-position: 500%;
    }
}

@keyframes animStar {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-2000px);
    }
}


/*-------------------------popup------------------------*/
/* credits: https://www.youtube.com/watch?v=lAS2glU0xlc */
.overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: -;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    transition: .5s ease-in-out;
}

.popup {
    position: relative;
    top: -43%;
    /* right: -100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    /* max-height: 500px; */
    width: auto;
    height: auto;
    margin: 56px;
    background-color: var(--bgColor);
    /* transform: rotate(32deg); */
    transform: scale(0);
    transition: .5s ease-in-out;
}

.popup-quote {
    font-family : Baskerville, Georgia, serif;
    font-style : italic;
    position: flex;
    color: var(--accentColor);
    padding: 20px;
    text-align: center;
    font-size: 1rem;
}

.popup-photo {
    display: flex;
    width: 100%;
    height: 100%;
}

.popup-photo img {
    width: 100%;
    height: 100%;
}

.overlay:target {
    visibility: visible;
    opacity: 1;
}

.overlay:target .popup {
    transform: scale(1);
    top: 0;
    /* right: 0; */
    /* transform: rotate(0); */
}

.popup-close {
    position: absolute;
    right: -1rem;
    top: -1rem;
    width: 3rem;
    height: 3rem;
    font-size: 1.7rem;
    font-weight: 400;
    border-radius: 100%;
    background-color: var(--bgColor);
    z-index: -2;
    color: var(--accentColor);
    line-height: 2.7rem;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

@media (hover: hover) {
    .popup-close:hover {
        background-color: var(--accentColor);
        color: var(--bgColor);
    }
}

.popup-close:active {
    background-color: var(--accentColor);
    color: var(--bgColor);
}

.linkedin-container {
    text-align: center;
    margin-top: 20px;
}

.linkedin-link {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1.2em;
    color: #ffffff;
    background-color: #0077B5;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.linkedin-link i {
    margin-right: 8px;
}

.linkedin-link:hover {
    background-color: #005582;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.teamText {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.founder {
    flex: 1;
    display: flex;
    align-items: center;
    background-color: #090a0f;
    padding: 0 0 50px 0; /* Adjust bottom padding for footer */
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 500px;  /* Set specific height for each founder box */
    box-sizing: border-box; /* Ensure padding is included in the height */
    z-index: 4;
    color: white; /* Set text color to white */
}

.founder-info {
    flex: 1;
}

.founder-info h2 {
    margin: 0 0 10px 0;
    font-size: 24px;
    color: white; /* Set text color to white */
}

.founder-info p {
    margin: 0;
    color: white; /* Set text color to white */
}

.founder-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: 20px;
}

.nav-menu .link-text {
    text-decoration: none;
  }

  .custom-footer {
    text-align: center;
    padding: 10px;
    background-color: #f1f1f1;
    margin-top: auto; /* Pushes the footer to the bottom */
}