.archive {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
.widget {
    display: flex;
    align-items: flex-start; /* Aligns content and image at the start */
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    width: 50%;
    margin: 0 auto;
    padding: 40px 40px;  /* Increased padding for more space */
    background-color: #090a0f;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: white; /* Set text color to white */
    box-sizing: border-box; /* Ensure padding is included in the width */
    z-index: 20;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
    text-decoration: none; /* Remove underline */
}

.widget-content {
    flex: 1;
    padding: 16px;
}

.widget-image {
    width: 200px;
    height: auto;
    margin-left: 16px;
}

.widget-title {
    font-size: 2.25rem;
    margin: 0;
}

.widget-summary {
    font-size: 1rem;
    margin: 8px 0;
    color: #555;
}

.widget-date {
    font-size: 0.875rem;
    color: #888;
    margin-top: 8px;
}

@media (hover: hover) {
    .archive-button:hover {
        background-color: var(--accentColor);
        color: var(--bgColor);
    }
    .widget:hover {
        transform: scale(1.1); /* Slightly increase size on hover */
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
    }
}

.archive-button:active {
    background-color: var(--accentColor);
    color: var(--bgColor);
}

.archive-button {
    display: inline-block; /* Use inline-block to prevent full-width stretching */
    position: relative; /* Relative positioning */
    background-color: transparent;
    color: var(--accentColor);
    cursor: pointer;
    border: solid var(--accentColor) 2px;
    border-radius: 10px;
    font-size: 1.5rem;
    text-align: center;
    margin: 10px auto 200px; /* Center the button horizontally */
    padding: 15px 30px; /* Increase padding for more space inside the button */
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    width: auto; /* Ensure the width is only as wide as the content */
    max-width: 100%; /* Prevent it from exceeding container width */
}


@media (max-width: 768px) {
    .archive-button {
        margin-top: 60px;
        padding:40px;
    }
    .widget {
        flex-direction: column;
        align-items: flex-start;
      }
    
    .widget-image {
        width: 80%;
        margin: 0 auto 15px; /* Center the image and add bottom margin */
        display: block;
    }

    .widget-content {
        align-items: flex-start;
    }

    .widget-title {
        font-size: 1.4em;
        margin-bottom: 10px;
    }

    .widget-summary {
        font-size: 1.1em;
        margin-bottom: 10px;
    }

    .widget-date {
        font-size: 1em;
    }
}

@media (max-width: 1000px) {
    .widget {
        width: 90%; /* Make the section wider on mobile for better readability */
    }
}