#bubbles-container {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: -5; /* Adjust for proper layering */
  }
  
  .bubble {
    position: absolute;
    bottom: -100px;
    background: transparent;
    border-radius: 50%;
    opacity: 0.1; /* Reduced opacity for less glossiness */
    animation: rise infinite;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.3); /* Reduced intensity of the glow */
    z-index: -5
  }
  
  .bubble::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 50%;
    border: 2px solid transparent;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1)); /* Reduced gradient intensity */
    mask: radial-gradient(circle, rgba(0,0,0,1) 60%, rgba(0,0,0,0) 70%);
    -webkit-mask: radial-gradient(circle, rgba(0,0,0,1) 60%, rgba(0,0,0,0) 70%);
    z-index: -5
  }
  
  @keyframes rise {
    0% {
      transform: translateY(0);
      opacity: 0.4; /* Match initial opacity */
      z-index: -5
    }
    100% {
      transform: translateY(-120vh);
      opacity: .1;
      z-index: -5
    }
  }